@import './bootstrap.min.css';
@import './grapes.css';

.flex-fill {
  flex-grow: 1;
  max-width: 100%;
  display: flex !important;
}

.rbc-day-slot.rbc-today,
.rbc-day-bg.rbc-today {
  background-color: transparent !important;
}

.rbc-time-slot {
  min-height: 30px;
}

.rbc-time-slot:hover {
  background-color: #ededed;
}

.object-fit-contain {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.object-fit-cover {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: none !important;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1ab394 !important;
  border-color: #1ab394 !important;
}

.component-container {
  min-height: 100px;
}

.editable-component-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.shadow-sm {
  box-shadow: rgb(225, 225, 225) 0 0 10px;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-hidden {
  overflow: hidden;
}

.title {
  position: relative;
}

.title h2 {
  background: transparent;
  display: inline-block;
  position: relative;
  padding: 0 5%;
  z-index: 999;
  color: #282828;
  font-weight: 400;
}

.title::before {
  background: rgb(235, 235, 235) none repeat scroll 0 0;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  top: 17px;
  width: 45%;
}

.title::after {
  background: rgb(235, 235, 235) none repeat scroll 0 0;
  content: '';
  height: 1px;
  right: 0;
  position: absolute;
  top: 17px;
  width: 45%;
}

.cursor-pointer {
  cursor: pointer;
}

.opacity-0 {
  opacity: 0;
}

.gridContainerWrapper {
  overflow: hidden;
}

.ellipsis-typography {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  /* @page {
    size: a3;
  } */
  /* body {
    min-width: 992px !important;
  } */
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

@media print {
  @page {
    size: a4;
  }
  body {
    min-width: min-content !important;
  }
  .page-break {
    page-break-after: always;
  }
}
