html {
  font-size: 14px;
}

html,
body,
#root {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

@media print {
  html,
  body,
  #root {
    background: none !important;
    overflow: visible;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: url('./assets/HomePage.png') center;
  background-size: cover;
}

input {
  border: none;
}

ul {
  padding: 0 0 0 10px;
  margin: 0;
}

input::placeholder {
  color: #b7bbbf;
}

.theme-background {
  background: url('./assets/HomePage.png') center;
  background-size: cover;
}

.slick-slider {
  width: 100%;
}
.slick-slider,
.slick-slider * {
  min-height: 0;
  min-width: 0;
}
.slick-arrow.slick-prev {
  left: 5px;
  z-index: 1;
}
.slick-arrow.slick-next {
  right: 5px;
  z-index: 1;
}
.slick-dots {
  bottom: 0 !important;
  z-index: 1;
}

.btn.focus,
.btn:focus,
button:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

.drawer {
  width: 260px;
}

.drawer-hidden {
  width: 0;
}

.drawer-header {
  min-height: 64px;
}

.content {
  width: calc(100% - 260px);
  float: right;
}

.content.content-lg {
  width: 100%;
}

.card-header h6 {
  line-height: 1.98;
}

.dropdown-toggle::after {
  display: none !important;
}

.Toastify__toast--error {
  background: #dc3545 !important;
}
textarea {
  min-height: 100px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

.blur {
  filter: blur(4px);
}

/* login:start */
.role-selection h1 {
  font-size: 100%;
  margin-bottom: 20px;
}

.role-selection button {
  margin-bottom: 20px;
}
/* login:end */

/* button:start */
.btn-100 {
  min-width: 100px;
}
/* button:end */

/*tools:start*/
.ml1rem {
  margin-left: 1rem;
}

.mb10 {
  margin-bottom: 10px;
}
.mr10 {
  margin-right: 10px;
}

.mr5 {
  margin-right: 5px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.pt20 {
  padding-top: 20px;
}

.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
/*tools:end*/

/* color:start */
.gray {
  color: gray;
}
/* color:end */

.primary-hover:hover {
  color: #1ab394 !important;
  text-decoration: none;
}

.primary-hover:hover * {
  color: #1ab394 !important;
}

/*.react-datepicker__time-container {*/
/*  width: 100px !important;*/
/*}*/

/*.react-datepicker__time-box {*/
/*  width: 100px !important;*/
/*}*/

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__input-container {
  display: block !important;
}

.rbc-month-view {
  height: auto !important;
}

.omniwe-modal-title h5 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.nav-link {
  cursor: pointer;
}

.html-wrapper img {
  display: inline;
  max-width: 100%;
  /*width: 100%;*/
}

/*.ClientScheduleWrapper .rbc-event {*/
/*  background-color: #1ab394;*/
/*}*/

#MainWrapper {
  min-height: 100vh;
}

.input-border-control {
  border-color: rgba(0, 0, 0, 0.23) !important;
}
.input-border-control:hover {
  border-color: rgba(0, 0, 0, 0.87) !important;
}

/*For @wojtekmaj/react-daterange-picker/dist/entry.nostyle*/
.react-daterange-picker {
  display: inline-flex;
  position: relative;
  color: #aaa;
}
.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #aaa;
}
.react-daterange-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: thin solid #ccc;
  border-radius: 10px;
}
.react-daterange-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-daterange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-daterange-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-daterange-picker__button svg {
  stroke: #ccc;
}

.react-daterange-picker__button:enabled {
  cursor: pointer;
}
.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
  stroke: #0078d7;
}
.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #6d6d6d;
}
.react-daterange-picker__button svg {
  display: inherit;
}
.react-daterange-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-daterange-picker__calendar--closed {
  display: none;
}
.react-daterange-picker__calendar .react-calendar {
  border-width: thin;
}

/*For react-calendar*/
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #ccc;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #ff7676;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #ffa9a9;
}
.react-calendar__tile--active {
  background: #dc0000;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ff1010;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.channelTab {
  overflow: visible !important;
}
.channelTab::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 10px;
  background: #ffffff;
  bottom: -10px;
}

/* ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
} */

/* ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset
  0 0 6px rgba(255, 255, 255, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #3f404a;
  outline: 1px solid slategrey;
} */

/*.MuiSkeleton-root {*/
/*  transition: width 1s ease-in-out;*/
/*}*/

.MuiInputAdornment-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.MuiInputAdornment-root .MuiOutlinedInput-root {
  background: none !important;
}

@keyframes notice {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-8px);
  }
  100% {
    transform: translateX(0px);
  }
}

.on-hash {
  animation-name: notice;
  -moz-animation-name: notice;
  -webkit-animation-name: notice;
  -o-animation-name: notice;
  animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  -webkit-animation-duration: 0.2s;
  -o-animation-duration: 0.2s;
  animation-iteration-count: 2;
  -moz-animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -o-animation-iteration-count: 2;
}

.form-row-placeholder {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
}

.single-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.two-lines-clamped {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@supports (-webkit-line-clamp: 2) {
  .two-lines-clamped {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.Mui-disabled {
  cursor: not-allowed;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* new Button color */

.MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.26) !important;
}

/* Delete Icon */
.delete-icon-btn {
  /* background-color: #ff4731 !important; */
  background-color: transparent !important;
  border-radius: 100% !important;
  color: #cc433c !important;
  /* color: #fff !important; */
}

.delete-icon-btn > * {
  color: #cc433c !important;
  /* color: #fff !important; */
}

.delete-icon-btn:hover {
  box-shadow: none !important;
  background-color: #d5d5d5 !important;
  color: #fff !important;
  border-radius: '30px';
  padding: '10px 10px';
}

/* normal */
.normal-btn {
  background-color: #ffffff !important;
  box-shadow: none !important;
  /* color: #899199 !important; */
  color: #7f858d !important;
  border: 1px solid #d0d0d0 !important;
}

.normal-btn:hover {
  background-color: #f5f4f4 !important;
  box-shadow: none !important;
  color: #7f858d !important;
}

/* warning */
.warning-btn {
  background-color: #ff4731 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.warning-btn:hover {
  background-color: #df3d2a !important;
  box-shadow: none !important;
  color: #fff !important;
}

/* omniew-red */
.omniew-red-btn {
  background-color: #cc433c !important;
  box-shadow: none !important;
  color: #fff !important;
}

.omniew-red-btn:hover {
  background-color: #c12c24 !important;
  box-shadow: none !important;
  color: #fff !important;
}

.MuiButton-contained:hover {
  box-shadow: none !important;
}

.MuiButton-label {
  text-transform: none !important;
}

.mce-statusbar {
  border-width: 0px !important;
}
