.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
}

.panel__basic-actions {
  position: initial;
}

#gjs {
  border: 3px solid #444;
  /*height: 100% !important;*/
  /*min-height: 600px;*/
}

/* Reset some default styling */
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}

.gjs-block {
  width: auto;
  height: auto;
  min-height: auto;
}

.editor-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  height: 100%;
}

.editor-canvas {
  flex-grow: 1;
}

.panel__right {
  flex-basis: 230px;
  position: relative;
  overflow-y: auto;
}

.panel__switcher {
  position: initial;
}

/*.gjs-one-bg {*/
/*background-color: #FFFFFF;*/
/*}*/

/*.gjs-two-color {*/
/*color: #444;*/
/*}*/

/*.gjs-three-bg {*/
/*background-color: #1ab394;*/
/*color: white;*/
/*}*/

/*.gjs-four-color,*/
/*.gjs-four-color-h:hover {*/
/*color: #1ab394;*/
/*}*/
